<template lang="pug">
.box_score
  v-container.tag_container(style="padding: 0", fluid)
    v-tabs.tag-switcher(
      background-color="transparent",
      ripple="false",
      v-model="tab",
      centered
    )
      v-tabs-slider(hidden)
      v-tab(@click="switchTable(0)", :ripple="false") {{ gameData.home_team_name }}
      v-tab(@click="switchTable(1)", :ripple="false") {{ gameData.away_team_name }}
  v-container.mt-10
    v-row(justify="end")
      v-col.pb-0(cols="auto")
        v-icon(small, color="error") mdi-information-outline
        span.overline.text_secondary.ml-1 點擊數據觀看精彩影片
    v-row.table_row.mt-0
      v-col#sticky-nav.table.table_col
        table
          thead
            tr.top_head
              sticky-column.bdr(colspan="1", :offset="0")
              sticky-column.bdr(colspan="1", :offset="50") 登錄
              sticky-column.bdr(colspan="1", :offset="100") 
              th.bdr(colspan="1") 時間
              th.bdr(colspan="5") 
              th.bdr(colspan="3") 投籃
              th.bdr(colspan="3") 二分
              th.bdr(colspan="3") 三分
              th.bdr(colspan="3") 罰球
              th.bdr(colspan="2") 籃板
              th(colspan="8")
            tr.bottom_head
              sticky-column.bdl(
                style="width: 50px; min-width: 50px",
                :title="'先發'",
                :offset="0"
              )
              sticky-column(
                style="width: 50px; min-width: 50px",
                :title="'背號'",
                :offset="50"
              )
              sticky-column.bdr2(
                style="width: 100px; min-width: 100px",
                :title="'球員'",
                :offset="100"
              )
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('min', 'BOX')",
                :class="{ sort_up: sortValue.type == 'min' && sortValue.order == 'ASC', sort_down: sortValue.type == 'secs' && sortValue.order == 'DESC' }"
              )
                span 上場時間
                font-awesome-icon.ml-2(:icon="getTableSort('min')")
              th(
                style="cursor: pointer",
                @click="sortClick('pts', 'BOX')",
                :class="{ sort_up: sortValue.type == 'pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pts' && sortValue.order == 'DESC' }"
              ) 
                span 得分
                font-awesome-icon.ml-2(:icon="getTableSort('pts')")
              th(
                style="cursor: pointer",
                @click="sortClick('reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'reb' && sortValue.order == 'DESC' }"
              ) 
                span 籃板
                font-awesome-icon.ml-2(:icon="getTableSort('reb')")
              th(
                style="cursor: pointer",
                @click="sortClick('ast', 'BOX')",
                :class="{ sort_up: sortValue.type == 'ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ast' && sortValue.order == 'DESC' }"
              ) 
                span 助攻
                font-awesome-icon.ml-2(:icon="getTableSort('ast')")
              th(
                style="cursor: pointer",
                @click="sortClick('stl', 'BOX')",
                :class="{ sort_up: sortValue.type == 'stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'stl' && sortValue.order == 'DESC' }"
              ) 
                span 抄截
                font-awesome-icon.ml-2(:icon="getTableSort('stl')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('blk', 'BOX')",
                :class="{ sort_up: sortValue.type == 'blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'blk' && sortValue.order == 'DESC' }"
              ) 
                span 阻攻
                font-awesome-icon.ml-2(:icon="getTableSort('blk')")
              th(
                style="cursor: pointer",
                @click="sortClick('fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('fgm')")
              th(
                style="cursor: pointer",
                @click="sortClick('fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('fg_a')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('fg_pct', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg_pct' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg_pct' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('fg_pct')")
              th(
                style="cursor: pointer",
                @click="sortClick('fg2m', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg2m' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg2m' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('fg2m')")
              th(
                style="cursor: pointer",
                @click="sortClick('fg2a', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg2a' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg2a' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('fg2a')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('fg2_pct', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg2_pct' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg2_pct' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('fg2_pct')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('fg3m', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg3m' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg3m' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('fg3m')")
              th(
                style="cursor: pointer",
                @click="sortClick('fg3a', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg3a' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg3a' && sortValue.order == 'DESC' }"
              ) 
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('fg3a')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('fg3_pct', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fg3_pct' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fg3_pct' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('fg3_pct')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('ftm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'ftm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ftm' && sortValue.order == 'DESC' }"
              ) 
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('ftm')")
              th(
                style="cursor: pointer",
                @click="sortClick('fta', 'BOX')",
                :class="{ sort_up: sortValue.type == 'fta' && sortValue.order == 'ASC', sort_down: sortValue.type == 'fta' && sortValue.order == 'DESC' }"
              ) 
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('fta')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('ft_pct', 'BOX')",
                :class="{ sort_up: sortValue.type == 'ft_pct' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ft_pct' && sortValue.order == 'DESC' }"
              ) 
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('ft_pct')")
              th(
                style="cursor: pointer",
                @click="sortClick('oreb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'oreb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'oreb' && sortValue.order == 'DESC' }"
              ) 
                span 進攻
                font-awesome-icon.ml-2(:icon="getTableSort('oreb')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('dreb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'dreb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'dreb' && sortValue.order == 'DESC' }"
              ) 
                span 防守
                font-awesome-icon.ml-2(:icon="getTableSort('dreb')")
              th(
                style="cursor: pointer",
                @click="sortClick('tov', 'BOX')",
                :class="{ sort_up: sortValue.type == 'tov' && sortValue.order == 'ASC', sort_down: sortValue.type == 'tov' && sortValue.order == 'DESC' }"
              ) 
                span 失誤
                font-awesome-icon.ml-2(:icon="getTableSort('tov')")
              th(
                style="cursor: pointer",
                @click="sortClick('pf', 'BOX')",
                :class="{ sort_up: sortValue.type == 'pf' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pf' && sortValue.order == 'DESC' }"
              ) 
                span 犯規
                font-awesome-icon.ml-2(:icon="getTableSort('pf')")
              th(
                style="cursor: pointer",
                @click="sortClick('plus_minus', 'BOX')",
                :class="{ sort_up: sortValue.type == 'plus_minus' && sortValue.order == 'ASC', sort_down: sortValue.type == 'plus_minus' && sortValue.order == 'DESC' }"
              ) 
                span +/-
                font-awesome-icon.ml-2(:icon="getTableSort('plus_minus')")
              th(
                style="cursor: pointer",
                @click="sortClick('eff', 'BOX')",
                :class="{ sort_up: sortValue.type == 'eff' && sortValue.order == 'ASC', sort_down: sortValue.type == 'eff' && sortValue.order == 'DESC' }"
              ) 
                span EFF
                font-awesome-icon.ml-2(:icon="getTableSort('eff')")
              th(
                style="cursor: pointer",
                @click="sortClick('ast_tov', 'BOX')",
                :class="{ sort_up: sortValue.type == 'ast_tov' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ast_tov' && sortValue.order == 'DESC' }"
              ) 
                span A/T
                font-awesome-icon.ml-2(:icon="getTableSort('ast_tov')")
          tbody
            tr(v-for="(player, index) in currentPlayerGameData", :key="index")
              sticky-column.bdl(
                :class="{ text_secondary: true }",
                style="width: 50px; min-width: 50px",
                :title="player.is_start ? '◯' : ''",
                :offset="0"
              ) 
              sticky-column(
                style="width: 50px; min-width: 50px",
                :title="player.season_player__jersey_number.toString()",
                :offset="50"
              )
              sticky-column.clickable.bdr2(
                @click.native="playerClick(player)",
                style="width: 100px; min-width: 100px",
                :title="player.season_player__name",
                :offset="100"
              )
              td.bdr {{ player.total.min }}
              td(
                @click="onPlayerStatClick(player, 'pts', '得分')",
                :class="[player.total.pts > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.pts }}
              td(
                @click="onPlayerStatClick(player, 'reb', '籃板')",
                :class="[player.total.reb > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.reb }}
              td(
                @click="onPlayerStatClick(player, 'ast', '助攻')",
                :class="[player.total.ast > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.ast }}
              td(
                @click="onPlayerStatClick(player, 'stl', '抄截')",
                :class="[player.total.stl > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.stl }}
              td.bdr(
                @click="onPlayerStatClick(player, 'blk', '阻攻')",
                :class="[player.total.blk > 0 ? 'link_deep' : 'gray_out_heavy']"
              ) {{ player.total.blk }}
              td(
                @click="onPlayerStatClick(player, 'fgm', '投籃命中')",
                :class="[player.total.fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fgm }}
              td(
                @click="onPlayerStatClick(player, 'fga', '投籃出手')",
                :class="[player.total.fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fga }}
              td.bdr {{ player.total.fg_pct }}%
              td(
                @click="onPlayerStatClick(player, 'fg2m', '二分命中')",
                :class="[player.total.fg2m > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fg2m }}
              td(
                @click="onPlayerStatClick(player, 'fg2a', '二分出手')",
                :class="[player.total.fg2a > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fg2a }}
              td.bdr {{ player.total.fg2_pct }}%
              td(
                @click="onPlayerStatClick(player, 'fg3m', '三分命中')",
                :class="[player.total.fg3m > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fg3m }}
              td(
                @click="onPlayerStatClick(player, 'fg3a', '三分出手')",
                :class="[player.total.fg3a > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fg3a }}
              td.bdr {{ player.total.three_pt_fg_percent  }}%
              td(
                @click="onPlayerStatClick(player, 'ftm', '罰球命中')",
                :class="[player.total.ftm > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.ftm }}
              td(
                @click="onPlayerStatClick(player, 'fta', '罰球出手')",
                :class="[player.total.fta > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.fta }}
              td.bdr {{ player.total.ft_pct }}%
              td(
                @click="onPlayerStatClick(player, 'oreb', '進攻籃板')",
                :class="[player.total.oreb > 0 ? 'link' : 'gray_out']",
                default=0
              ) {{ player.total.oreb }}
              td.bdr(
                @click="onPlayerStatClick(player, 'dreb', '防守籃板')",
                :class="[player.total.dreb > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.dreb }}
              td(
                @click="onPlayerStatClick(player, 'tov', '失誤')",
                :class="[player.total.tov > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.tov }}
              td(
                @click="onPlayerStatClick(player, 'pf', '犯規')",
                :class="[player.total.pf > 0 ? 'link' : 'gray_out']"
              ) {{ player.total.pf }}
              td {{ player.total.plus_minus }}
              td {{ player.total.eff }}
              td {{ player.total.ast_tov }}
  v-container
    v-row(style="margin-top: 100px")
      v-col.text-center
        h5.normal_title_h5 數據比較
    v-row
      v-col(cols="2")
      v-col.d-flex.titles.justify-space-between(cols="12", sm="8")
        h6.bar_title.clickable.text_deep_gray(@click="teamClick(true)") {{ gameData.home_team_name }}
        h6.bar_title.clickable.text_deep_gray(@click="teamClick(false)") {{ gameData.away_team_name }}
    v-row.align-center.mt-5(
      v-for="(ranking, index) in teamRankingList",
      :key="index"
    )
      v-col.text-right.pb-0.pb-sm-4(cols="12", sm="2")
        h6.bar_title.type {{ ranking.title }}
      v-col.d-flex.justify-space-between.pt-0.pt-sm-4(cols="12", sm="8")
        percentage-compare-bar.flex-shrink-1(
          :style="{ maxWidth: 'calc(' + getPercentageCompareRate(ranking.type, true) + ' - 1vw)' }",
          :type="ranking.type",
          :value="ranking.home.value",
          :deno="ranking.home.deno",
          :rate="ranking.home.deno != null"
        )
        percentage-compare-bar.flex-shrink-1(
          :style="{ maxWidth: 'calc(' + getPercentageCompareRate(ranking.type, false) + ' - 1vw)' }",
          :type="ranking.type",
          :value="ranking.away.value",
          :deno="ranking.away.deno",
          :reverse="true",
          :rate="ranking.home.deno != null"
        )
  v-container
    v-row(style="margin-top: 100px")
      v-col.text-center(cols="12")
        h5.normal_title_h5 最佳球員
    v-row.justify-center
      v-col.text-center.mt-6(
        v-for="(ranking, index) in gameData.player_stat_rankings",
        :key="index",
        cols="12",
        sm="10"
      )
        .player_data.d-flex.justify-space-between.align-center
          .home_player.d-flex.align-center
            .avator_container.temp_logo_hidden
              v-img.img_circle.clickable.mr-4(
                :src="ranking.home_team.img",
                @click="teamClick(true)"
              )
            .player_text.text-left
              h4.font-weight-bold.text-h4.text_middle_blue {{ ranking.home_team.value }}
              div(
                v-for="(player, i) in gameData.player_stat_rankings[index].home_team.players"
              )
                span.player_name.clickable(@click="bestPlayerClick(player)") {{ `${player.name}` }}
          span.type.font-weight-bold {{ ranking.title }}
          .away_player.d-flex.align-center
            .player_text.text-right
              h4.font-weight-bold.text-h4.text_secondary {{ ranking.away_team.value }}
              div(
                v-for="(player, i) in gameData.player_stat_rankings[index].away_team.players"
              )
                span.player_name.clickable(@click="bestPlayerClick(player)") {{ `${player.name}` }}
            .avator_container.temp_logo_hidden
              v-img.img_circle.clickable.ml-4(
                @click="teamClick(false)",
                :src="ranking.away_team.img"
              )
        .player_bars.d-flex.justify-space-between.mt-5
          .bar.bg_middle_blue(
            :style="{ flexBasis: 'calc(' + getPlayerCompareRate(ranking.stat, true) }"
          )
          .bar.bg_secondary(
            :style="{ flexBasis: 'calc(' + getPlayerCompareRate(ranking.stat, false) }"
          )
        .membership_team.d-flex.justify-space-between
          .team.home_team.clickable(@click="teamClick(true)") {{ ranking.home_team.team_name }}
          .team.away_team.clickable(@click="teamClick(false)") {{ ranking.away_team.team_name }}
  v-container
    v-row(style="margin-top: 100px")
      v-col.text-center(cols="12")
        h5.normal_title_h5 命中率分佈
    v-row.mt-12
      v-col.offset-sm-2.offset-md-0(cols="12", sm="8", md="6")
        p.text-center.position_team_name {{ gameData.home_team_name }}
        position-field#positionField1.mt-3(
          :positionList="homeShotPositionList"
          :mainColor="'#487AA4'"
          :subColor="'#96B9DC'"
          @positionClick="positionHomeClick"
        )
      v-col.offset-sm-2.offset-md-0(cols="12", sm="8", md="6")
        p.text-center.position_team_name {{ gameData.away_team_name }}
        position-field#positionField2.mt-3(
          :positionList="awayShotPositionList"
          :mainColor="'#E47076'"
          :subColor="'#F2B8BB'"
          @positionClick="positionAwayClick"
        )

  event-video-dialog(
    v-model="isDialogShow",
    :gameId="gameId"
    :teamId="teamId"
    :playerId="playerId"
    :position="position"
    :stat="stat"
  )

  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import PercentageCompareBar from "@/components/common/PercentageCompareBar";
import PositionField from "@/components/common/PositionField";
import moment from "moment";
import { getGameData, getEventByStat, getEventByPosition } from "@/api/game";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";
import { getIdFromUrl } from "vue-youtube";
import EventVideoDialog from "@/components/league/EventVideoDialog";

export default {
  name: "LeagueGameBoxScore",
  components: {
    StickyColumn,
    PercentageCompareBar,
    PositionField,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
    EventVideoDialog,
  },
  data() {
    return {
      league: this.$route.params.league,
      season: this.$route.params.season,
      seasonSegmentId:
        Number(this.$route.params.seasonSegmentId) > 0
          ? Number(this.$route.params.seasonSegmentId)
          : null,
      gameId: Number(this.$route.params.id),
      playerId: null,
      gameData: {},
      currentPlayerGameData: [],
      tab: 0,

      isDialogShow: false,
      position: "",
      stat: "",
      teamId: 0,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogNameShowType: true,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",

      teamRankingList: [
        {
          type: "fieldGoal",
          title: "投籃",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "twoPoint",
          title: "兩分",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "threePoint",
          title: "三分",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "freeThrow",
          title: "罰球",
          home: {
            value: 0,
            deno: 0,
          },
          away: {
            value: 0,
            deno: 0,
          },
        },
        {
          type: "assist",
          title: "助攻",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "tov",
          title: "失誤",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "rebound",
          title: "總籃板",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "offensiveRebound",
          title: "進攻籃板",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "defensiveRebound",
          title: "防守籃板",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "stl",
          title: "抄截",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
        {
          type: "blk",
          title: "阻攻",
          home: {
            value: 0,
          },
          away: {
            value: 0,
          },
        },
      ],

      feedBackPlay: null,

      homeShotPositionList: [],
      awayShotPositionList: [],
      sortValue: {
        type: "",
        order: "",
      },

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
      dialogFeedbackShow: false,
      dialogLoginShow: false,
    };
  },
  watch: {},
  created() {
    this.getGameData();
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortClick(type, sortData) {
      if (type == this.sortValue.type) {
        if (this.sortValue.order == "DESC") {
          this.sortValue.order = "ASC";
          if (this.tab == 0) {
            this.currentPlayerGameData = this.sortData(
              this.gameData.home_team_player_stats,
              type,
              sortData,
              "ASC"
            );
          } else if (this.tab == 1) {
            this.currentPlayerGameData = this.sortData(
              this.gameData.away_team_player_stats,
              type,
              sortData,
              "ASC"
            );
          }
        } else if (this.sortValue.order == "ASC") {
          this.sortValue.type = "";
          this.sortValue.order = "";
          if (this.tab == 0) {
            this.currentPlayerGameData = this.gameData.home_team_player_stats;
          } else if (this.tab == 1) {
            this.currentPlayerGameData = this.gameData.away_team_player_stats;
          }
        }
      } else {
        this.sortValue.type = type;
        this.sortValue.order = "DESC";
        if (this.tab == 0) {
          this.currentPlayerGameData = this.sortData(
            this.gameData.home_team_player_stats,
            type,
            sortData,
            "DESC"
          );
        } else if (this.tab == 1) {
          this.currentPlayerGameData = this.sortData(
            this.gameData.away_team_player_stats,
            type,
            sortData,
            "DESC"
          );
        }
      }
    },
    sortData(allList, sortKey, sortData, order) {
      let tempList = Object.assign([], allList);
      if ("INFO" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function(a, b) {
            return a.total[sortKey] - b.total[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function(a, b) {
            return b.total[sortKey] - a.total[sortKey];
          });
        }
      } else if ("BOX" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function(a, b) {
            return a.total[sortKey] - b.total[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function(a, b) {
            return b.total[sortKey] - a.total[sortKey];
          });
        }
      }
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          league: this.league,
          seasonSegmentId: this.seasonSegmentId,
          playerId: player.season_player__player__id,
        },
      });
    },
    bestPlayerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          league: this.league,
          seasonSegmentId: this.seasonSegmentId,
          playerId: player.player_id,
        },
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    getGameData() {
      let temp = {
        game_id: this.gameId,
      };
      getGameData(temp).then((response) => {
        this.gameData = response.data;
        this.switchTable(0);
        this.getTeamRankingData();
        this.getTeamShotPositionData();
      });
    },
    getTeamRankingData() {
      let home_team_stat = this.gameData.home_team_stat;
      let away_team_stat = this.gameData.away_team_stat;

      for (let i in this.teamRankingList) {
        let item = this.teamRankingList[i];
        if ("fieldGoal" == item.type) {
          item.home.value = home_team_stat.fgm;
          item.home.deno = home_team_stat.fga;

          item.away.value = away_team_stat.fgm;
          item.away.deno = away_team_stat.fga;
        } else if ("twoPoint" == item.type) {
          item.home.value = home_team_stat.fg2m;
          item.home.deno = home_team_stat.fg2a;

          item.away.value = away_team_stat.fg2m;
          item.away.deno = away_team_stat.fg2a;
        } else if ("threePoint" == item.type) {
          item.home.value = home_team_stat.fg3m;
          item.home.deno = home_team_stat.fg3a;

          item.away.value = away_team_stat.fg3m;
          item.away.deno = away_team_stat.fg3a;
        } else if ("freeThrow" == item.type) {
          item.home.value = home_team_stat.ftm;
          item.home.deno = home_team_stat.fta;

          item.away.value = away_team_stat.ftm;
          item.away.deno = away_team_stat.fta;
        } else if ("assist" == item.type) {
          item.home.value = home_team_stat.ast;
          item.away.value = away_team_stat.ast;
        } else if ("tov" == item.type) {
          item.home.value = home_team_stat.tov;
          item.away.value = away_team_stat.tov;
        } else if ("rebound" == item.type) {
          item.home.value = home_team_stat.reb;
          item.away.value = away_team_stat.reb;
        } else if ("offensiveRebound" == item.type) {
          item.home.value = home_team_stat.oreb;
          item.away.value = away_team_stat.oreb;
        } else if ("defensiveRebound" == item.type) {
          item.home.value = home_team_stat.dreb;
          item.away.value = away_team_stat.dreb;
        } else if ("stl" == item.type) {
          item.home.value = home_team_stat.stl;
          item.away.value = away_team_stat.stl;
        } else if ("blk" == item.type) {
          item.home.value = home_team_stat.blk;
          item.away.value = away_team_stat.blk;
        }
      }
    },
    getTeamShotPositionData() {
      this.homeShotPositionList = this.getShotPositionList(
        this.gameData.home_team_stat
      );
      this.awayShotPositionList = this.getShotPositionList(
        this.gameData.away_team_stat
      );
    },
    getPercentageCompareRate(type, isHome) {
      let item, total, rate;

      item = this.teamRankingList.find(function(ranking) {
        return ranking.type == type;
      });

      if (isHome) {
        if (item.home.deno) {
          total =
            item.home.value / item.home.deno + item.away.value / item.away.deno;
          rate = item.home.value / item.home.deno / total;
        } else {
          total = item.home.value + item.away.value;
          rate =
            type != "tov" ? item.home.value / total : item.away.value / total;
        }
      } else {
        if (item.away.deno) {
          total =
            item.home.value / item.home.deno + item.away.value / item.away.deno;
          rate = item.away.value / item.away.deno / total;
        } else {
          total = item.home.value + item.away.value;
          rate =
            type != "tov" ? item.away.value / total : item.home.value / total;
        }
      }
      return rate * 100 + "%";
    },
    getPlayerCompareRate(stat, isHome) {
      let item, total, rate;

      item = this.gameData.player_stat_rankings.find(function(ranking) {
        return ranking.stat == stat;
      });

      if (isHome) {
        total = item.home_team.value + item.away_team.value;
        rate = item.home_team.value / total;
      } else {
        total = item.home_team.value + item.away_team.value;
        rate = item.away_team.value / total;
      }
      return rate * 100 + "%";
    },
    switchTable(index) {
      this.sortValue.type = "";
      this.sortValue.order = "";
      switch (index) {
        case 0:
          this.tab = 0;
          this.currentPlayerGameData = this.gameData.home_team_player_stats;
          break;
        case 1:
          this.tab = 1;
          this.currentPlayerGameData = this.gameData.away_team_player_stats;
          break;
        default:
          break;
      }
    },
    getShotPositionList(teamStat) {
      let tempList = [];

      let PAINT = {
        name: "PAINT",
        fga: teamStat.paint_fga,
        fgm: teamStat.paint_fgm,
        fg_pct: teamStat.paint_fg_pct,
      };
      tempList.push(PAINT);

      let TOP_OF_THE_ARC_2_PTS = {
        name: "TOP_OF_THE_ARC_2_PTS",
        fga: teamStat.top_fg2a,
        fgm: teamStat.top_fg2m,
        fg_pct: teamStat.top_fg2_pct,
      };
      tempList.push(TOP_OF_THE_ARC_2_PTS);

      let TOP_OF_THE_ARC_3_PTS = {
        name: "TOP_OF_THE_ARC_3_PTS",
        fga: teamStat.top_fg3a,
        fgm: teamStat.top_fg3m,
        fg_pct: teamStat.top_fg3_pct,
      };
      tempList.push(TOP_OF_THE_ARC_3_PTS);

      let LEFT_BASELINE_2_PTS = {
        name: "LEFT_BASELINE_2_PTS",
        fga: teamStat.left_baseline_fg2a,
        fgm: teamStat.left_baseline_fg2m,
        fg_pct: teamStat.left_baseline_fg2_pct,
      };
      tempList.push(LEFT_BASELINE_2_PTS);

      let RIGHT_BASELINE_2_PTS = {
        name: "RIGHT_BASELINE_2_PTS",
        fga: teamStat.right_baseline_fg2a,
        fgm: teamStat.right_baseline_fg2m,
        fg_pct: teamStat.right_baseline_fg2_pct,
      };
      tempList.push(RIGHT_BASELINE_2_PTS);

      let LEFT_WING_2_PTS = {
        name: "LEFT_WING_2_PTS",
        fga: teamStat.left_wing_fg2a,
        fgm: teamStat.left_wing_fg2m,
        fg_pct: teamStat.left_wing_fg2_pct,
      };
      tempList.push(LEFT_WING_2_PTS);

      let RIGHT_WING_2_PTS = {
        name: "RIGHT_WING_2_PTS",
        fga: teamStat.right_wing_fg2a,
        fgm: teamStat.right_wing_fg2m,
        fg_pct: teamStat.right_wing_fg2_pct,
      };
      tempList.push(RIGHT_WING_2_PTS);

      let LEFT_BASELINE_3_PTS = {
        name: "LEFT_BASELINE_3_PTS",
        fga: teamStat.left_baseline_fg3a,
        fgm: teamStat.left_baseline_fg3m,
        fg_pct: teamStat.left_baseline_fg3_pct,
      };
      tempList.push(LEFT_BASELINE_3_PTS);

      let RIGHT_BASELINE_3_PTS = {
        name: "RIGHT_BASELINE_3_PTS",
        fga: teamStat.right_baseline_fg3a,
        fgm: teamStat.right_baseline_fg3m,
        fg_pct: teamStat.right_baseline_fg3_pct,
      };
      tempList.push(RIGHT_BASELINE_3_PTS);

      let LEFT_WING_3_PTS = {
        name: "LEFT_WING_3_PTS",
        fga: teamStat.left_wing_fg3a,
        fgm: teamStat.left_wing_fg3m,
        fg_pct: teamStat.left_wing_fg3_pct,
      };
      tempList.push(LEFT_WING_3_PTS);

      let RIGHT_WING_3_PTS = {
        name: "RIGHT_WING_3_PTS",
        fga: teamStat.right_wing_fg3a,
        fgm: teamStat.right_wing_fg3m,
        fg_pct: teamStat.right_wing_fg3_pct,
      };
      tempList.push(RIGHT_WING_3_PTS);

      return tempList;
    },
    onPlayerStatClick(player, stat, event) {
      this.playerId = player.season_player__player__id;
      this.stat = stat;
      this.isDialogShow = true;
    },
    positionHomeClick(position) {
      this.teamId = this.gameData.home_team__team_id;
      this.stat = "";
      this.position = position;
      this.isDialogShow = true;
    },
    positionAwayClick(position) {
      this.teamId = this.gameData.away_team__team_id;
      this.stat = "";
      this.position = position;
      this.isDialogShow = true;
    },
    dataClick(stat, link) {
      if (!link) return;
      this.stat = stat;
      this.position = "";
      this.isDialogShow = true;
    },
    teamClick(isHomeTeam) {
      let id;

      if (isHomeTeam) {
        id = this.gameData.home_team__team_id;
      } else {
        id = this.gameData.away_team__team_id;
      }

      this.$router.push({
        name: "LeagueTeam",
        params: {
          leagueId: this.league,
          teamId: id,
          seasonSegmentId: this.seasonSegmentId,
        },
      });
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/league/game/box_score";
</style>
